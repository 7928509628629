<template>
  <div class="city-statistic">
    <v-container>
      <h2 class="ml-3 text-center">Ваш город {{ objCityNames.title }}?</h2>
      <v-row id="statistic-container">
        <v-col cols="12" sm="4">
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: 1,
            }"
            min-height="200"
            transition="fade-transition"
          >
            <v-card hover outlined class="city-statistic__card ml-1">
              <div class="doctor-icon icon"></div>
              <div class="card_title">
                Врачи <br />{{ objCityNames.cities_doctors }}
              </div>
              <div class="card_body">
                <div
                  data-num="5300"
                  id="doctor-counter"
                  v-if="objCityCount.doctorsCount"
                >
                  <counter-component
                    :number="objCityCount.doctorsCount"
                  ></counter-component>
                </div>
              </div>
            </v-card>
          </v-lazy>
        </v-col>
        <v-col cols="12" sm="4">
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: 1,
            }"
            min-height="200"
            transition="fade-transition"
          >
            <v-card hover outlined class="city-statistic__card">
              <div class="service-icon icon"></div>
              <div class="card_title">
                Услуги в <br />{{ objCityNames.cities_services }}
              </div>
              <div class="card_body">
                <div
                  v-if="objCityCount.servicesCount"
                  data-num="12919"
                  id="service-counter"
                >
                  <counter-component
                    :number="objCityCount.servicesCount"
                  ></counter-component>
                </div>
              </div>
            </v-card>
          </v-lazy>
        </v-col>
        <v-col cols="12" sm="4">
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: 1,
            }"
            min-height="200"
            transition="fade-transition"
          >
            <v-card hover outlined class="city-statistic__card mr-1">
              <div class="clinic-icon icon"></div>
              <div class="card_title">
                Клиники <br />{{ objCityNames.cities_doctors }}
              </div>
              <div class="card_body">
                <div
                  v-if="objCityCount.clinicsCount"
                  data-num="530"
                  id=" clinic-counter"
                >
                  <counter-component
                    :number="objCityCount.clinicsCount"
                  ></counter-component>
                </div>
              </div>
            </v-card>
          </v-lazy>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CounterComponent from "../city-statistic/CounterComponent";
import { mapGetters, mapActions } from "vuex";
import * as names from "store/names";
export default {
  name: "CityStatisticComponent",
  components: { CounterComponent },
  mounted() {
    this.getCityCount(this.selectedCity.id);
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    ...mapGetters({
      selectedCity: names.OBJ_SELECTED_CITY,
      objCityCount: names.OBJ_CITY_COUNT,
      objCityNames: names.OBJ_CITY_NAMES,
    }),
  },
  methods: {
    ...mapActions({
      getCityCount: names.ACTION_GET_CITY_COUNT,
    }),
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";

.city-statistic {
  margin-bottom: 60px;

  h2 {
    margin-bottom: 40px;
  }

  .doctor-icon {
    mask: url(~icons/doctor.svg) no-repeat center / contain;
    -webkit-mask: url(~icons/doctor.svg) no-repeat center / contain;
  }

  .service-icon {
    mask: url(~icons/service.svg) no-repeat center / contain;
    -webkit-mask: url(~icons/service.svg) no-repeat center / contain;
  }

  .clinic-icon {
    mask: url(~icons/clinic.svg) no-repeat center / contain;
    -webkit-mask: url(~icons/clinic.svg) no-repeat center / contain;
  }

  &__card {
    width: 300px;
    height: 300px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: center;
    margin: auto;
    overflow: hidden;
    cursor: default;
    @include phone-only {
      margin: auto !important;
    }

    &:hover {
      box-shadow: 0 2px 6px 10px #e3ecfc;
      background: linear-gradient(-45deg, #c4d5d1, #23a6d5, #0044cc, #0500cc);
      background-size: 400% 400%;
      animation: gradient 5s ease infinite;
      color: #fff;

      .icon {
        background-color: initial;
      }

      .card_body {
        color: #ffffff;
      }
    }

    .icon {
      pointer-events: none;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
      top: 50px;
      right: 60px;
      background-color: $primary-color;
      @include tablet-down {
        margin: auto;
      }
    }

    .card_title {
      font-size: 32px;
      font-weight: 700;
      line-height: 34px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      z-index: 5;

      @include tablet-down {
        font-size: 28px;
        flex-direction: column;
        align-items: center;
      }
    }

    .card_body {
      font-size: 60px;
      font-weight: 600;
      text-align: center;
      color: #49515a;
      position: relative;
      z-index: 5;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
</style>
