<template>
  <transition name="fade-transition" v-if="resultTemplate">
    <v-card flat class="text-center main-search-result pa-3">
      <!--Сортировка и фильтры-->
      <main-result-sort v-if="resultTemplate" :key="resultTemplate"/>
      <!--Результаты поиска-->
      <component
        :is="resultTemplate"
        v-if="resultTemplate"
        :data="{ entityId, entityName }"
      />
    </v-card>
  </transition>
</template>

<script>
import * as names from "store/names";
import { mapGetters } from "vuex";
import MainResultSort from "components/main-search/templates/filters/MainResultSort";
export default {
  name: "main-search-result",
  components: { MainResultSort },
  data: () => ({
    resultTemplate: null,
  }),
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === "template/MUTATE_SELECT_RESULT_TEMPLATE") {
        this.resultTemplate = () => this.loader();
      }
    });
  },
  computed: {
    ...mapGetters({
      resultTemplateName: names.RESULT_TEMPLATE_NAME,
      entityId: names.ENTITY_ID,
      entityName: names.ENTITY_NAME,
    }),
    loader() {
      if (!this.resultTemplateName) {
        return null;
      }
      return () => import(`./templates/${this.resultTemplateName}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-search-result {
  margin: 20px 0;
  overflow: visible;
}
</style>
