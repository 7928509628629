<template>
  <v-tabs background-color="transparent">
    <v-tab
      class="search-tab-item"
      @click="selectSearch"
      data-value="doctor"
      data-placeholder="Начните вводить ФИО врача или специальность"
    >
      <div class="doctor-icon icon"></div>
      Врач
    </v-tab>
    <v-tab
      class="search-tab-item"
      @click="selectSearch"
      data-value="service"
      data-placeholder="Начните вводить название услуги"
      ><div class="service-icon icon"></div>
      Услуга
    </v-tab>
    <v-tab
      class="search-tab-item"
      @click="selectSearch"
      data-value="clinic"
      data-placeholder="Начните вводить название клиники"
      ><div class="clinic-icon icon"></div>
      Клиника
    </v-tab>
  </v-tabs>
</template>

<script>
import * as names from "../../store/names";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MainSearchNavigation",
  computed: {
    ...mapGetters({
      searchType: names.SEARCH_TYPE,
      searchValue: names.SEARCH_TYPE,
    }),
  },
  methods: {
    ...mapActions({
      teleportSearchType: names.ACTION_TRANSMISSION_SEARCH_TYPE,
      teleportSearchPlaceholder: names.ACTION_TRANSMISSION_SEARCH_PLACEHOLDER,
      clearSearchValue: names.ACTION_TRANSMISSION_SEARCH_VALUE,
      clearFirstDoctorServices: names.ACTION_CLEAR_OBJ_SERVICE_BY,
      clearSearchObj: names.ACTION_CLEAR_SEARCH_OBJ,
      clearEntityId: names.ACTION_TRANSMISSION_ENTITY_ID,
      clearEntityName: names.ACTION_TRANSMISSION_ENTITY_NAME,
      clearResultTemplateName: names.ACTION_TRANSMISSION_RESULT_TEMPLATE_NAME,
    }),
    selectSearch(event) {
      /*Получаем данные с ивента и записываем в переменную*/
      let element = event.target,
        searchType = element.dataset.value,
        searchPlaceholder = element.dataset.placeholder;

      /*Записываем значения из переменных в состояния VueX*/
      this.teleportSearchType(searchType);
      this.teleportSearchPlaceholder(searchPlaceholder);

      /*Очищаем VueX от старых значений*/
      this.clearSearchValue("");
      this.clearEntityId(null);
      this.clearEntityName(null);
      this.clearResultTemplateName(null);
      this.clearFirstDoctorServices();
      this.clearSearchObj();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

.doctor-icon {
  mask: url(~icons/doctor.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/doctor.svg) no-repeat center / contain;
}

.service-icon {
  mask: url(~icons/service.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/service.svg) no-repeat center / contain;
}

.clinic-icon {
  mask: url(~icons/clinic.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/clinic.svg) no-repeat center / contain;
}

.search-tab-item {
  text-transform: none;
  background: rgba(#e6e4e4, 0.5);
  cursor: pointer;
  text-align: center;
  font-family: $sanspro, sans-serif;
  font-style: normal;
  font-weight: normal;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  color: #000000 !important;
  padding: 10px 5px;
  margin-right: 2px;
  width: 160px;
  letter-spacing: normal;
  @include phone-only {
    width: 36%;
    height: auto;
  }

  &:last-child {
    margin-right: 0;
  }

  .icon {
    pointer-events: none;
    width: 20px;
    height: 13px;
    background-color: #0a0a0a;
  }
}
.v-tab--active {
  color: #0044cc !important;
  background: #e3ecfc !important;
  font-weight: 400 !important;
  .icon {
    background-color: $deep-blue;
  }
}
</style>
