import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import isArray from "lodash/isArray";
import has from "lodash/has";
export default {
  data() {
    return {
      emphasis: [],
    };
  },
  methods: {
    needLeftMargin(suggestion, elem, idx) {
      let emphasis = this.emphasis[suggestion.id];

      if (emphasis[idx].value.length === 0) {
        return false;
      }

      if (!elem.isMarked) {
        return false;
      }

      if (idx === 0) {
        return false;
      }

      if (idx > 0) {
        let previousValue = emphasis[idx - 1].value;

        if (
          // Если в предыдущем слове есть строка от 1 символа без пробелов вначале
          previousValue.trim().length > 0 &&
          // Если в предыдущем слове последняя буква
          previousValue.slice(-1) !== " "
        ) {
          return true;
        }
      }
    },
    needRightMargin(suggestion, elem, idx) {
      let emphasis = this.emphasis[suggestion.id];

      if (emphasis[idx].value.length === 0) {
        return false;
      }

      if (!elem.isMarked) {
        return false;
      }

      if (idx < emphasis.length - 1) {
        let nextValue = emphasis[idx + 1].value;

        if (
          // Если дальше есть строка от 1 символа без пробелов вначале
          nextValue.trim().length > 0 &&
          // Если дальше нет строки, которая начинается с пробела
          nextValue.substring(0, 1) !== " "
        ) {
          return true;
        }
      }
    },
    generateEmphasis(initialValueForSuggestion) {
      if (
        isNull(this.suggestions) ||
        isUndefined(this.suggestions) ||
        !isArray(this.suggestions)
      ) {
        return;
      }

      this.emphasis = {};
      let searchParts = this.searchValue.split(" ");
      searchParts.forEach((searchPart) => {
        this.suggestions.forEach((suggestion) => {
          let forPartResult = [];
          if (!has(this.emphasis, suggestion.id)) {
            this.emphasis[suggestion.id] = [
              {
                isMarked: false,
                value: initialValueForSuggestion(suggestion),
              },
            ];
          }

          this.emphasis[suggestion.id].forEach((suggestionPart) => {
            if (suggestionPart.isMarked) {
              forPartResult.push(suggestionPart);
            } else {
              // Оставляем в строке только буквы
              let removeSymbols = searchPart.replace(/[^A-Za-zА-Яа-яЁё]/g, "");
              let re = new RegExp(removeSymbols, "i");
              let matchIdx = suggestionPart.value.search(re);

              if (matchIdx < 0) {
                forPartResult.push(suggestionPart);
                return;
              }

              let leftPart = suggestionPart.value.substr(0, matchIdx);
              let findPart = suggestionPart.value.substr(
                matchIdx,
                removeSymbols.length
              );
              let rightPart = suggestionPart.value.substr(
                matchIdx + removeSymbols.length
              );

              if (leftPart.length > 0) {
                forPartResult.push({
                  isMarked: false,
                  value: leftPart,
                });
              }

              forPartResult.push({
                isMarked: true,
                value: findPart,
              });

              if (rightPart.length > 0) {
                forPartResult.push({
                  isMarked: false,
                  value: rightPart,
                });
              }
            }
          });
          this.emphasis[suggestion.id] = forPartResult;
        });
      });
    },
  },
};
