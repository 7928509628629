<template>
  <div>
    {{ displayNumber }}
  </div>
</template>

<script>
export default {
  name: "CounterComponent",
  props: { number: { default: 0 } },

  data: () => ({
    displayNumber: 0,
    interval: false,
  }),

  mounted() {
    this.numberCount();
  },

  methods: {
    numberCount() {
      clearInterval(this.interval);

      if (this.number == this.displayNumber) {
        return;
      }
      this.interval = window.setInterval(
        function () {
          if (this.displayNumber !== this.number) {
            let change = (this.number - this.displayNumber) / 10;

            change = change >= 0 ? Math.ceil(change) : Math.floor(change);

            this.displayNumber = this.displayNumber + change;
          }
        }.bind(this),
        20
      );
    },
  },
};
</script>

<style scoped></style>
