var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.objPopularSpecialization)?_c('div',{staticClass:"popular-specialization"},[_c('v-container',[_c('h2',{staticClass:"ml-3 text-center"},[_vm._v(" Популярные специализации в "+_vm._s(_vm.objCityNames.cities_services)+" ")]),_c('v-lazy',{attrs:{"options":{
        threshold: 1,
      },"min-height":"200","transition":"fade-transition"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-chip-group',{attrs:{"column":"","active-class":"text-primary"}},_vm._l((_vm.objPopularSpecialization),function(tag,index){return _c('v-chip',{key:index,attrs:{"outlined":"","label":"","href":'/search/' +
              _vm.selectedCity.url +
              '/specialization/'+
              tag.url +
              '/' +
              tag.specialization_id,"color":"primary"}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1)],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }