<template>
  <v-container>
    <main-search-component></main-search-component>
    <advantages-component />
    <city-statistic-component />
    <feedback-component></feedback-component>
    <popular-specialization-component></popular-specialization-component>
    <button-back-to-top />
  </v-container>
</template>

<script>
import MainSearchComponent from "components/main-search/MainSearchComponent";
import AdvantagesComponent from "components/advantages/AdvantagesComponent";
import FeedbackComponent from "components/feedback/FeedbackComponent";
import CityStatisticComponent from "components/city-statistic/CityStatisticComponent";
import PopularSpecializationComponent from "components/popular-specialization/PopularSpecializationComponent";
import ButtonBackToTop from "components/partials/ButtonBackToTop";
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";

const COMPONENT_NAME = "the-home-page";
export default {
  props: ["city", "entity", "id"],
  mounted() {
    this.getResultByUrl();
  },
  metaInfo: {
    title: "Главная страница",
    titleTemplate: "%s ← Medveddoc",
    meta: [
      {
        name: "description",
        content:
            "Подбор врача по цене, клинике. График работы врачей. Подбор медицинских услуг по РФ",
      },
      { charset: "utf-8" },
      { property: "og:title", content: "Главная страница" },
      { property: "og:site_name", content: "medveddoc.com" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://medveddoc.com" },
      {
        property: "og:description",
        content:
            "Подбор врача по цене, клинике. График работы врачей. Подбор медицинских услуг по РФ",
      },
    ],
  },

  name: COMPONENT_NAME,
  components: {
    PopularSpecializationComponent,
    CityStatisticComponent,
    AdvantagesComponent,
    MainSearchComponent,
    FeedbackComponent,
    ButtonBackToTop,
  },
  computed: {
    ...mapGetters({
      selectedCity: names.OBJ_SELECTED_CITY,
      cities: names.OBJ_CITIES,
    }),
  },
  methods: {
    ...mapActions({
      selectResultTemplate: names.ACTION_SELECT_RESULT_TEMPLATE,
      getCities: names.ACTION_GET_CITIES,
      saveCity: names.ACTION_SAVE_SELECTED_CITY,
    }),
    /*TODO: говнокод, надо бы переписать (можно сделать кейсами). Это метод для поиска через get параметры для SEO*/
    async getResultByUrl() {
      if (
          this.city !== undefined &&
          this.entity !== undefined &&
          this.id !== undefined
      ) {
        if (this.city === this.selectedCity.url) {
          if (
              this.entity === "user" ||
              this.entity === "specialization" ||
              this.entity === "service" ||
              this.entity === "clinic"
          ) {
            let data = {
              id: this.id,
              entity: null,
            };
            let entity =
                this.entity.charAt(0).toUpperCase() + this.entity.slice(1);
            await this.selectResultTemplate({
              templateName: `MainResult${entity}`,
              data: data,
            });
          }
        } else {
          await this.getCities();
          let value = this.cities.find((el) => el.url === this.city);
          if (value !== undefined) {
            await this.saveCity({ city: value });
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  max-width: 1185px;
}
</style>
