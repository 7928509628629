<template>
  <v-dialog width="800px" v-model="mapAddress" @click:outside="destroyMap">
    <v-card class="map-container mx-auto">
      <v-card-title class="d-flex justify-content-between align-start">
        <v-row>
          <v-col>
            <v-text-field
                outlined
                dense
                id="address"
                text
                v-model="address"
                placeholder="Укажите на карте ваше местоположение"
                disabled
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-btn
                outlined
                class="btn-detail"
                color="primary"
                @click="saveDataMap"
            >
              Найти
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="!mapError" class="map-wrapper">
        <div v-show="readyMap">
          <div id="map" class="map-body">
          </div>
        </div>
        <v-layout align-center justify-center class="mt-10">
          <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-layout>
      </v-card-text>
      <v-card-text v-if="mapError" class="map-wrapper">
        Что-то пошло не так, пожалуста, попробуйте перезагрузить страницу.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="destroyMap">
         Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {loadYmap} from "vue-yandex-maps";
import * as names from "store/names";

let myMap;
export default {
  data: () => ({
    loading: false,
    readyMap: false,
    mapError: false,
    address: "",
    coordinatesLat: null,
    coordinatesLong: null,
  }),
  name: "MapAddressModal",
  computed: {
    ...mapGetters({
      selectedCity: names.OBJ_SELECTED_CITY,
      mapAddress: names.MAP_ADDRESS_DIALOG,
    }),
  },
  async mounted() {
    this.loading = true;
    setTimeout(() => this.mapLoading(), 3000);

    const settings = {
      apiKey: "ffce6b87-86ce-4cfd-90cf-2ee8c1adb403",
      lang: "ru_RU",
      coordorder: "latlong",
      version: "2.1",
    };

    await loadYmap(settings, {debug: true});
    // Как только будет загружен API и готов DOM, выполняется инициализация
    window.ymaps.ready(this.initMap);
  },

  methods: {
    ...mapActions({
      closeMap: names.ACTION_SAVE_MAP_ADDRESS_DIALOG,
      saveMapCoordinates: names.ACTION_SAVE_MAP_COORDINATES,

    }),
    mapLoading() {
      this.loading = false;
      this.readyMap = true;
    },
    //TODO: сделать нормальные карты, поиск при вводе адреса вручную
    initMap() {
      let myInput = document.getElementById("address");
      let myPlacemark;
      myMap = new window.ymaps.Map('map', {
        center: [	48.7194, 44.5018],
        zoom: 12
      }, {
        searchControlProvider: 'yandex#search'
      });

      //Слушаем клик на карте.
      myMap.events.add('click', (events) => {
        let coords = events.get('coords');

        // Если метка уже создана – просто передвигаем.
        if (myPlacemark) {
          myPlacemark.geometry.setCoordinates(coords);
        }
        // Если метки нет – создаем.
        else {
          myPlacemark = createPlacemark(coords);
          myMap.geoObjects.add(myPlacemark);
          // Слушаем событие окончания перетаскивания самой метки.
          myPlacemark.events.add('dragend', function () {
            getAddress(myPlacemark.geometry.getCoordinates());
          });
        }
        getAddress(coords);
      });

      // Создаем метку.
      function createPlacemark(coords) {
        return new window.ymaps.Placemark(coords, {
          iconCaption: 'поиск...'
        }, {
          preset: 'islands#violetDotIconWithCaption',
          draggable: true
        });
      }
      //TODO: разобрать что к чему и почему
      // Определяем адрес по координатам (используется обратное геокодирование).
      function getAddress(coords) {
        myPlacemark.properties.set('iconCaption');
        window.ymaps.geocode(coords).then(function (res) {
          let firstGeoObject = res.geoObjects.get(0),
              address = firstGeoObject.getAddressLine();
          myPlacemark.properties
              .set({
                // Формируется строка с данными об объекте.
                iconCaption: [
                  // Название населенного пункта или вышестоящее административно-территориальное образование.
                  firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
                  // Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
                  firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
                ].filter(Boolean).join(', '),
                // Контента балуна задаем строку с адресом объекта.
                balloonContent: firstGeoObject.getAddressLine()
              });
          myInput.value = address;
          localStorage.setItem('value', firstGeoObject.getAddressLine());
          localStorage.setItem('lat', coords[0]);
          localStorage.setItem('long', coords[1]);
        });
      }
      this.address = localStorage.getItem('value');
      this.coordinatesLat = localStorage.getItem('lat'),
      this.coordinatesLong = localStorage.getItem('long');

    },

    destroyMap() {
      myMap.destroy();
      this.closeMap(false);
    },
    async saveDataMap() {
      this.address = localStorage.getItem('value');
      this.coordinatesLat = localStorage.getItem('lat'),
      this.coordinatesLong = localStorage.getItem('long');

      if (!this.address) {
        return this.$notifier.showMessage("Укажите Ваш адрес", "warning");
      };

      await this.saveMapCoordinates({
        mapCoordinatesLat: this.coordinatesLat,
        mapCoordinatesLong: this.coordinatesLong,
        myAddress: this.address,
      })
      this.destroyMap();
    },
  },
};
</script>

<style scoped lang="scss">
.map-container {
  max-width: 800px;
  max-height: 700px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.map-wrapper {
  min-height: 400px;
}
.map-body {
  max-width: 800px;
  height: 400px;
  .span {
    font-size: 1rem;
  }
}
</style>
