<template>
  <v-container fluid v-if="availableFilters">
    <v-row align="center">
      <v-col
        class="d-flex"
        v-if="availableFilters"
      >
        <v-select
          :items="sortValues"
          v-model="selectedSortValue"
          @change="saveSelectedSort"
          item-text="name"
          item-value="value"
          label="Сортировка"
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col
        v-if="!searchType.includes('service') && !searchType.includes('clinic')"
        class="d-flex"
      >
          <v-select
                  :items="typeValues"
                  v-model="selectedTypeValue"
                  @change="saveSelectedSort"
                  item-text="title"
                  item-value="id"
                  label="Работает с детьми?"
                  dense
                  outlined
          ></v-select>
      </v-col>
      <v-col
        v-if="availableFilters"
        class="d-flex"
      >
        <v-select
          :items="cityParts"
          v-model="selectedCityParts"
          @change="saveSelectedSort"
          item-text="title"
          item-value="id"
          label="Выберите Район"
          dense
          outlined
        >
          <template
            v-slot:selection="{ item, index }"
            v-if="index === 1" class="primary--text caption"
          >
            <span>{{ item.title }}</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12"
             class="d-flex margin"
             center
             v-if="selectedSortValue == 'distance_asc'"
      >
        <v-text-field
            placeholder="Укажите Ваш адрес"
            v-model="myAddress"
            outlined
            dense
            @click="showMap"
        ></v-text-field>
        <map-address-modal v-if="mapAddress" />
      </v-col>
    </v-row>
    <v-row v-if="availableFilters">
      <v-col v-if="!searchType.includes('clinic')">
        <v-range-slider
          v-if="maxPriceSearch && minPriceSearch"
          @end="saveSelectedSort"
          :max="maxPriceSearch + 0.1"
          :min="minPriceSearch"
          v-model="range"
          label="Цена:"
          thumb-label
          hide-details
          dense
          ref="form"
        >
        </v-range-slider>
      </v-col>
    </v-row>
    <v-row v-if="!searchType.includes('clinic') && (searchType.includes('doctor'), availableFilters.includes('price'))"
    >
      <v-col>
        <v-text-field
          :value="range[0]"
          label="От:"
          outlined
          dense
          type="number"
          v-model="range[0]"
          @change="saveSelectedSort"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :value="range[1]"
          label="До:"
          outlined
          dense
          type="number"
          v-model="range[1]"
          @change="saveSelectedSort"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as names from "store/names";
import { mapActions, mapGetters } from "vuex";
import MapAddressModal from "components/partials/MapAddressModal";

export default {
  name: "MainResultSort",
  components: { MapAddressModal },
    data: () => ({
    selectedSortValue: "price_asc",
    selectedCityParts: null,
    selectedTypeValue: null,
    range: [],
    minPrice: 0,
}),
  created() {
    this.range[0] = this.minPriceSearch;
    this.getCityParts({ cityId: this.selectedCity.id });
  },
  watch: {
    maxPriceSearch: {
      handler(price) {
        if (price) {
          this.setRange();
        }
      },
      immediate: true,
    },
    minPriceSearch: {
      handler(price) {
        this.$refs.form.reset();
        if (price) {
          this.setRange();
        }
      },
      immediate: true,
    },
    myCoordinatesLat: {
      handler() {
        if (this.myCoordinatesLat) {
          this.saveSelectedSort();
        }
      },
      immediate: true,
    },
    myCoordinatesLong: {
      handler() {
        if (this.myCoordinatesLong) {
          this.saveSelectedSort();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      availableFilters: names.AVAILABLE_FILTERS,
      sortValues: names.OBJ_SEARCH_SORT_VALUES,
      typeValues: names.OBJ_SEARCH_TYPE_VALUES,
      maxPriceSearch: names.MAX_PRICE_SEARCH,
      minPriceSearch: names.MIN_PRICE_SEARCH,
      cityParts: names.OBJ_CITY_PARTS,
      selectedCity: names.OBJ_SELECTED_CITY,
      searchType: names.SEARCH_TYPE,
      mapAddress: names.MAP_ADDRESS_DIALOG,
      myCoordinatesLat: names.MY_COORDINATES_LAT,
      myCoordinatesLong: names.MY_COORDINATES_LONG,
      myAddress: names.MY_ADDRESS,
    }),
  },
  methods: {
    ...mapActions({
      saveSelectedSortValues: names.ACTION_SAVE_SELECTED_SORT_VALUES,
      getCityParts: names.ACTION_GET_CITY_PARTS,
      openMap: names.ACTION_SAVE_MAP_ADDRESS_DIALOG,
    }),
    saveSelectedSort() {
      this.saveSelectedSortValues({
        sortValue: this.selectedSortValue,
        typeValue: this.selectedTypeValue,
        cityParts: this.selectedCityParts,
        price: this.range,
        MyCoordinatesLat: this.myCoordinatesLat,
        MyCoordinatesLong: this.myCoordinatesLong,
      });
    },
    setRange() {
      this.range[1] = this.maxPriceSearch;
      this.range[0] = this.minPriceSearch;
    },
    showMap() {
      this.openMap(true);
    },
  },
};
</script>

<style lang="scss">
.margin {
  margin: -20px auto;
}
</style>
