<template>
  <v-container class="feedback">
    <v-lazy
        v-model="isActive"
        :options="{
          threshold: .5
        }"
        min-height="200"
        transition="fade-transition"
    >
      <v-card class="feedback__card-animation" outlined>
        <v-row class="mt-5">
          <v-col cols="12">
            <div class="feedback__text">
              <h2 class="main-title">
                Остались вопросы? <br />
                Нужна помощь в подборе врача?
              </h2>
              <p>
                Оставьте свои контакты и наши специалисты свяжутся с вами в
                ближайшее время.
              </p>
            </div>
          </v-col>
        </v-row>
        <v-form v-model="valid" id="send" ref="form">
          <v-container>
            <v-row class="d-flex align-center justify-center">
              <v-col cols="12" sm="6">
                <v-text-field
                        :prepend-inner-icon="icons.mdiFaceProfile"
                        color="#0044CC"
                        v-model="name"
                        :rules="nameRules"
                        label="Ваше имя"
                        outlined
                        flat
                        dense
                >
                </v-text-field>
                <v-text-field
                        :prepend-inner-icon="icons.mdiPhone"
                        class="form_control"
                        label="(999) 999-9999"
                        v-model="phone"
                        prefix="+7"
                        outlined
                        clearable
                        :single-line="true"
                        :rules="phoneRules"
                        v-mask="'(###) ###-####'"
                        dense
                ></v-text-field>
                <v-checkbox
                        class="mt-0 pt-0"
                        v-model="personalData"
                        :off-icon="icons.mdiCheckboxBlankOutline"
                        :on-icon="icons.mdiCheckboxMarked"
                        label="Согласие на обработку персональных данных"
                ></v-checkbox>
                <v-scroll-y-transition>
                  <a target="_blank"
                     href="https://old.medveddoc.com/docs/personal_data_protection.pdf"
                     class="mt-0 pl-8"
                     v-if="personalData">Положение о защите персональных данных
                  </a>
                </v-scroll-y-transition>
                  <v-col class="mb-3">
  <!--                    <vue-recaptcha-->
  <!--                            ref="recaptcha"-->
  <!--                            :sitekey="sitekey"-->
  <!--                            @expired="onCaptchaExpired"-->
  <!--                    />-->
                  </v-col>
              </v-col>
            </v-row>
            <div class="d-flex align-center justify-center mt-5">
              <v-btn
                      color="primary"
                      outlined
                      @click="sendFeedback"
                      :disabled="disabled"
                      onclick="ym(64731667, 'reachGoal', 'FEEDBACK'); ga('send', 'event', 'FEEDBACK', 'click'); return true;"
              >
                Отправить
              </v-btn>
            </div>
          </v-container>
        </v-form>
      </v-card>
    </v-lazy>
  </v-container>
</template>

<script>
import { mdiPhone, mdiEmail, mdiFaceProfile } from "@mdi/js";
import { mapActions } from "vuex";
// import VueRecaptcha from "vue-recaptcha";
import * as names from "store/names";

export default {
  name: "FeedbackComponent",
    // components: { VueRecaptcha },
  data: () => ({
    icons: {
      mdiPhone,
      mdiFaceProfile,
      mdiEmail,
    },
    isActive: false,
    valid: false,
    name: "",
    phone: "",
    emptyValue: false,
    disabled: false,
    personalData: false,
    nameRules: [
        (value) => !!value || "Введите имя",
        (value) => value.length <= 50 || "Максимальная длина строки 50 символов",
        (value) =>
                /^[\sa-zA-Zа-яёА-ЯЁ-]+$/.test(value) ||
                "Имя может состоять только из букв, пробелов и тире",
      ],
    phoneRules: [
        (value) => !!value || "Введите телефон",
        (value) =>
                /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10}(\s*)?$/.test(value) ||
                "Введите корректный телефон",
      ],
    // sitekey: process.env.VUE_APP_SITEKEY,
  }),
  methods: {
    ...mapActions({
      addFeedback: names.ACTION_SEND_OBJ_FEEDBACK,
    }),

      // onCaptchaExpired () {
      //     this.$refs.recaptcha.reset()
      // },

    async sendFeedback() {
      if (!this.name || this.name === " ") {
        return this.$notifier.showMessage("Введите имя", "warning");
      }
      if (!this.phone) {
        return this.$notifier.showMessage("Введите телефон", "warning");
      }
      if (this.phone[1] == 8) {
        return this.$notifier.showMessage("Введите номер телефона без восьмерки", "warning");
      }
      if (!this.personalData) {
        return this.$notifier.showMessage(
        "Вы должны согласиться на обработку персональных данных",
        "warning"
      );
      }

      // // eslint-disable-next-line no-undef
      //   this.recaptcha_token = grecaptcha.getResponse();

        // if (!this.recaptcha_token) {
        //     return this.$notifier.showMessage(
        //         "Подтвердите, что Вы не робот!",
        //         "warning"
        //     );
        // }

      let orderPhone = this.phone.match(/\d+/g).join("");

      this.disabled = true;

      let response = await this.addFeedback({
        type: "contact",
        text: null,
        doctor_id: null,
        department_id: null,
        rating: null,
        email: null,
        phone: orderPhone,
        name: this.name,
        // recaptcha_token: this.recaptcha_token,
      });

        if (response === "success") {
          this.name = "";
          this.phone = "";
          this.personalData = false;
          this.emptyValue = true;
          this.disabled = false;
          return this.$refs.form.reset(),
                 // this.onCaptchaExpired(),
                 this.$notifier.showMessage(
                  "Ваша заявка успешно отправлена!",
                  "success"
          )
      } else {
          this.disabled = false;
          return this.$refs.form.reset(),
                 // this.onCaptchaExpired(),
                 this.$notifier.showMessage(
                  "Что-то пошло не так, пожалуйста попробуйте снова!",
                  "error"
          )
        }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.feedback {
  margin: 60px 0;
  &__card {
    border: 1px solid #e6e4e4;
    background: #ffffff;
    color: white;
    min-height: 250px;
    margin: auto;
    &:hover {
      box-shadow: 0 2px 6px 10px $primary-color;
    }
    &-animation {
      padding: 20px;
      margin: auto;
      position: relative;
      box-shadow: 0 2px 6px 5px #e3ecfc;
      background: linear-gradient(-45deg, #e3ecfc, #e3ecfc, #ffffff, #e3ecfc);
      background-size: 400% 400%;
      animation: gradient 5s ease infinite;
    }

  }

  &__text {
    text-align: center;
    color: #000;
    line-height: 25px;

    @include phone-only {
      font-size: 16px;
    }
    p {
      margin-top: 20px;
    }
  }

  .v-text-field {
    margin-top: 10px;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
